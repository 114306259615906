import React from 'react'
import '../styles/pricing.scss'
import Layout from "../components/layout";

const Pricing = () => (
    <Layout>
        <div className='plan-container banner-pricing'>
            {/*<img className="banner" src="/images/pay-in-person-reader.webp"/>*/}
            <div className='price-title'>Get started for <span>FREE</span> and upgrade plan later</div>
            <div className='pricing-wrapper'>
                <div className='pricing-container'>
                    <div className='plan-name'>Basic</div>
                    <div className='plan-text'>All the basics for starting a new business</div>
                    <div>
                        <span className='plan-price'><sup>$</sup>0</span>
                        <span><sub> /Location/Month</sub></span>
                    </div>
                    <div className='plan-features'>
                        <p>Includes:</p>
                        <p>
                            <ul>
                                <li>Unlimited Customers</li>
                                <li>Unlimited Staff</li>
                                <li>Unlimited Products</li>
                                <li>Basic Sales Reports</li>
                                <li>Pay-By-Text</li>
                                <li>Register Management</li>
                                <li>Order Management</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className='pricing-container'>
                    <div className='plan-name'>Advanced</div>
                    <div className='plan-text'>Advanced features for scaling your business</div>
                    <div>
                        <span className='plan-price'><sup>$</sup>129</span>
                        <span><sub> /Location/Month</sub></span>
                    </div>
                    <div className='plan-features'>
                        <p>Includes everything in Basic, plus:</p>
                        <p>
                            <ul>
                                <li>Online Ordering</li>
                                <li>Invoicing</li>
                                <li>Kitchen Display Solution</li>
                                <li>Advanced Reports</li>
                                <li>Staff Tip Management</li>
                                <li>Staff Time Management</li>
                                <li>Staff Access Control</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>

            <div className='pricing-container processing-fee'>
                <div className='plan-name'>Processing Fee</div>
                <div className='plan-text'>Process all card payments (Swipe/Dip/Tap/Manual, In-Store/Online) at </div>
                <div>
                    <span className='plan-fee'>3.5% + 30¢ </span>
                    <span><sub> USD/Transaction</sub></span>
                </div>
            </div>
        </div>

    </Layout>
);

export default Pricing
